import React, { useContext, useEffect, useState } from 'react';
import './Home.css';
import Explorer from '../Explorer/Explorer';
import { getFilteredCards } from '../../Api';
import FilterHeader from '../Explorer/FilterHeader';
import { getFilters } from '../../Api';
import { removeFilter } from '../Explorer/ExplorerHelpers';
import Modal from 'react-modal';
import { SpinnerCircular } from 'spinners-react';
import { toast } from 'react-toast';
import { getTraitTypes } from '../../Api';
import { Data } from '../../DataContext';
import CardOverlay from '../Explorer/CardOverlay/CardOverlay';
import config from '../../config';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};
const Home = () => {
  const [filters, setFilters] = useState({});
  const [isDataLoaded, setDataLoaded] = useState(false);
  const [activeFilters, setActiveFilters] = useState(null);
  const [nfts, setNfts] = useState([]);
  const [filtering, setFiltering] = useState(false);
  const [projectName, setProjectName] = useState('Project');
  const [showTokenId, setShowTokenId] = useState(true);

  const { setTraits, showCardOverlay } = useContext(Data);
  // get trait and filter types
  useEffect(() => {
    (async () => {
      getTraitTypes().then((res) => {
        setTraits(res);
      });
      getFilters().then((res) => {
        setFilters(res);
      });
    })();
  }, []);

  const filterChanged = (keyname, value) => {
    let updatedValue = {};
    if (keyname === 'MintedOnly' && value === false) {
      delete activeFilters['MintedOnly'];
    } else updatedValue[keyname] = value;

    setActiveFilters((activeFilters) => ({
      ...activeFilters,
      ...updatedValue,
    }));
  };
  const showToken = (state) => {
    setShowTokenId(state);
  };

  //filtering - if activefilters object changes we call the api
  useEffect(() => {
    /// remove the "All" filter before the api call
    let filters = activeFilters ? removeFilter(activeFilters) : {};
    setNfts([]);

    (async () => {
      setFiltering(true);
      getFilteredCards(filters)
        .then((response) => {
          if (response.length > 0) {
            setFiltering(false);
            setNfts(response);
            setProjectName(response[0].collection_name);
            setDataLoaded(true);
          } else {
            setFiltering(false);
            setDataLoaded(true);
          }
        })
        .catch((e) => {
          setFiltering(false);
          toast.error(e);
        });
    })();
  }, [activeFilters]);

  return (
    <>
      {config.SHOW_EXPLORER ? (
        <>
          <FilterHeader
            filters={filters}
            filterChanged={filterChanged}
            projectName={projectName}
            showToken={showToken}
          />
          <div className="container home">
            <div>
              {isDataLoaded && (
                <Explorer
                  nfts={nfts}
                  filtersAreActive={activeFilters}
                  showToken={showTokenId}
                />
              )}
            </div>
            <Modal isOpen={filtering} style={customStyles}>
              <SpinnerCircular size={100} color="rgb(252, 108, 3)" />
            </Modal>
          </div>
          {showCardOverlay && <CardOverlay />}
        </>
      ) : (
        'Nothing to see here..'
      )}
    </>
  );
};

export default Home;
