import './App.css';
import { HashRouter, Route, Switch } from 'react-router-dom';
import routes from './Routes';
import {  ToastContainer} from 'react-toast'
import config from './config';
import DataContext from './DataContext';

function App(props) {

  return (
      <div id="application" className="h-100">
          <HashRouter basename='/'>
            <DataContext>
            <Switch>
              {routes.map((route, key)=>(
                <Route key={key} path={route.path} component={route.component} exact={route.exact} />
              ))}
            </Switch>
            </DataContext>
          </HashRouter>
        <ToastContainer delay={4000}  position="bottom-right"/>
      </div>
  );
}

export default App;
