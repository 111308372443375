import { GalaxisCard } from 'galaxis-components';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getMetadata } from '../../../Api';

// const metadata = {
//   tokenId: 1,
//   image:
//     'https://ether-cards.mypinata.cloud/ipfs/QmVwHf3BGbw6Cdz27AZVfXB29La4QGUL1K4zL5NuHJKtGe/64/1664.jpeg',
//   name: 'The Hedgie Fund #1',
//   collection_type: 'galaxis',
//   sides: [{ id: 1, dna: '1217050506050509' }],
//   collection_name: 'The Hedgie Fund',
//   attributes: [
//     { value: "Mrs Mouton's Kitchen #1", trait_type: 'Background' },
//     { value: 'Normal Green', trait_type: 'Prickles' },
//     { value: 'Plain', trait_type: 'Body' },
//     { value: 'Up', trait_type: 'Ears' },
//     { value: 'Sleepy', trait_type: 'Face' },
//     { value: 'NONE', trait_type: 'Hat' },
//     { value: 'NONE', trait_type: 'Birthmarks' },
//     { value: 'Just Keep Stimming...', trait_type: 'Arms' },
//     { trait_type: 'Utility trait', value: 'Background' },
//     { trait_type: 'Utility trait', value: 'Face' },
//     { trait_type: 'Utility trait', value: 'Arms' },
//   ],
//   traits: [
//     {
//       id: 2,
//       name: 'Background',
//       type: '7',
//       description:
//         'Sam loves analysing the patterns on the kitchen floor - he likes to pretend that he can only step on the blue squares.',
//     },
//     {
//       id: 57,
//       name: 'Face',
//       type: '7',
//       description: 'Sam gets sleepy, just like another other lamb...',
//     },
//     {
//       id: 110,
//       name: 'Arms',
//       type: '7',
//       description:
//         'Stimming is a repetitive behaviour that many autistic kids perform and it can be very helpful to calm them down in stressful situations.',
//     },
//   ],
// };

const traitTypes = [
  {
    id: 1,
    name: 'Physical Redeemables',
    icon_white:
      '/storage/app/uploads/public/trait_type_icons/physical-white.svg',
    icon_orange:
      '/storage/app/uploads/public/trait_type_icons/physical-orange.svg',
    icon_black:
      '/storage/app/uploads/public/trait_type_icons/physical-black.svg',
  },
  {
    id: 2,
    name: 'Digital Redeemables',
    icon_white:
      '/storage/app/uploads/public/trait_type_icons/digital-white.svg',
    icon_orange:
      '/storage/app/uploads/public/trait_type_icons/digital-orange.svg',
    icon_black:
      '/storage/app/uploads/public/trait_type_icons/digital-black.svg',
  },
  {
    id: 3,
    name: 'Discount Traits',
    icon_white:
      '/storage/app/uploads/public/trait_type_icons/discount-white.svg',
    icon_orange:
      '/storage/app/uploads/public/trait_type_icons/discount-orange.svg',
    icon_black:
      '/storage/app/uploads/public/trait_type_icons/discount-black.svg',
  },
  {
    id: 4,
    name: 'Access Traits',
    icon_white: '/storage/app/uploads/public/trait_type_icons/access-white.svg',
    icon_orange:
      '/storage/app/uploads/public/trait_type_icons/access-orange.svg',
    icon_black: '/storage/app/uploads/public/trait_type_icons/access-black.svg',
  },
  {
    id: 5,
    name: 'Modifiers',
    icon_white:
      '/storage/app/uploads/public/trait_type_icons/modifier-white.svg',
    icon_orange:
      '/storage/app/uploads/public/trait_type_icons/modifier-orange.svg',
    icon_black:
      '/storage/app/uploads/public/trait_type_icons/modifier-black.svg',
  },
  {
    id: 6,
    name: 'Meeting',
    icon_white:
      '/storage/app/uploads/public/trait_type_icons/meeting-white.svg',
    icon_orange:
      '/storage/app/uploads/public/trait_type_icons/meeting-orange.svg',
    icon_black:
      '/storage/app/uploads/public/trait_type_icons/meeting-black.svg',
  },
  {
    id: 7,
    name: 'Badges',
    icon_white: '/storage/app/uploads/public/trait_type_icons/badge-white.svg',
    icon_orange:
      '/storage/app/uploads/public/trait_type_icons/badge-orange.svg',
    icon_black: '/storage/app/uploads/public/trait_type_icons/badge-black.svg',
  },
  {
    id: 8,
    name: 'Signature',
    icon_white: '/storage/app/uploads/public/trait_type_icons/access-white.svg',
    icon_orange:
      '/storage/app/uploads/public/trait_type_icons/autograph-orange.svg',
    icon_black:
      '/storage/app/uploads/public/trait_type_icons/autograph-black.svg',
  },
];


const RenderCard = () => {
  const location = useLocation();
  const metadataURL = location.search.slice(1);
  const [metadata, setmetadata] = useState(null)

  useEffect(() => {
    (async () => {
      getMetadata('https://' + metadataURL).then((response) => {
        setmetadata(response);
      }).catch((e)=>{
        console.log(e);
      });
    })();
  }, []);
  return (
    <div className="card-overlay">
      {metadata && <GalaxisCard metadata={metadata} traitTypes={traitTypes} />}
    </div>
  );
};

export default RenderCard;
