import { useContext, useState } from 'react';
import { SpinnerCircular } from 'spinners-react';
import './ExplorerCard.css';
import { LazyImage } from './LazyImage';
import { Data } from '../../DataContext';

const ExplorerCard = ({ card, showToken }) => {
  const [loading, setLoading] = useState(true);
  const { showOverlayForCard, setTheChosenCard } = useContext(Data);

  const CardDetails = () => {
    return (
      <div className="card-details">
        <ul>
          {card.attributes.map((attribute) => {
            return (
              <li style={{ paddingTop: '15px' }} key={attribute.trait_type}>
                <p className="trait-type">{Object.values(attribute)[1]}</p>
                <p className="trait-value">{Object.values(attribute)[0]}</p>
              </li>
            );
          })}
        </ul>
      </div>
    );
  };

  const choseCards = () => {
    setTheChosenCard(card);
    showOverlayForCard(true);
  };

  return (
    <div className="root" onClick={choseCards}>
      <div className="image-container">
        <div style={{ position: 'relative', minHeight: '100px' }}>
          <SpinnerCircular
            color="#000"
            style={{
              display: loading ? 'block' : 'none',
              margin: 'auto',
              position: 'absolute',
              left: 0,
              right: 0,
              top: 0,
              bottom: 0,
            }}
          />
          <CardDetails />
          <LazyImage
            src={card.image}
            imageOnLoad={() => setLoading(false)}
            alt="Card"
          />
        </div>
        {!loading && (
          <div className="card-content" style={{ zIndex: '1' }}>
            <div>
              <p style={{ color: '#fff', minHeight: 50 }}>
                {card.collection_name}
              </p>
            </div>
            <div>
              {showToken && (
                <p style={{ color: '#fff', minHeight: 50 }}>#{card.tokenId}</p>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ExplorerCard;
