import Home from './components/Home/Home';
import RenderCard from './components/Explorer/RenderCard/RenderCard';



const routes = [
     {
          'path': '/:param/:param',
          'component': Home,
          'exact': true
     },
     {
          'path': '/render/',
          'component': RenderCard,
          'exact': true
     },
];

export default routes;

