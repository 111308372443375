import './CardOverlay.css'
import { Data } from '../../../DataContext'
import { useContext } from 'react'
import { GalaxisCard } from 'galaxis-components';
import closeIcon from '../../../assets/images/white_close_4x.png'

const CardOverlay = () => {
    const { chosenCard, showOverlayForCard, traitTypes } = useContext(Data);
    console.log(chosenCard.toString())
    return <div className="card-overlay" onClick={() => showOverlayForCard(false)}>
        <button className='close-btn' onClick={() => showOverlayForCard(false)}><img alt='undefined' src={closeIcon} /></button>
        <div style={{width:"100%",height: "100%", maxWidth:"700px"}}>
        <GalaxisCard metadata={chosenCard} traitTypes={traitTypes} />
        </div>
    </div>
}

export default CardOverlay;