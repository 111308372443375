import { useState } from 'react';
import './CustomFileterSelect.css';

const CustomFilterSelect = ({ keyname, filters, filterChanged }) => {
  const [selectValue, setSelectValue] = useState('All');
  const [opened, setOpened] = useState(false);



  const toggleOpen = ()=>{
    setOpened(!opened);
  }

  const handleSelectChange = (keyname, value) => {
    setSelectValue(value);
    filterChanged(keyname, value);
  };


  const renderFilters = () => {
    return filters[keyname].map((filter,idx) => {
     // return <option key={`filter` + Math.random()}>{filter}</option>;
      return (
       <div key={`filter` + idx} onClick={(e) => handleSelectChange(keyname, filter)}
       className={`cust-filter-item ${selectValue===filter?'selected':''}`}
       >{filter}</div>
      );
    });
  };

  return (
    <div className="d-flex flex-column mt-2 mx-2 custom-filter-select"
       onClick={toggleOpen}
       onMouseLeave={()=>setOpened(false)}>
      <label className={`mb-0 ${opened?'open':''}`}>{keyname} <span className='arrow'>&#9660;</span></label>
      <div className={`filter-dropdown`}>
        <div onClick={(e) => handleSelectChange(keyname, 'All')} className={`cust-filter-item ${selectValue==='All'?'selected':''}`}>All</div>
        {renderFilters()}
      </div>
      
      <div className='filter-value'>
        {selectValue?selectValue:'All'}
      </div>

      
      
      {/* <div className="select-parent">
      
        <select
          value={selectValue}
          onChange={(e) => handleSelectChange(keyname, e)}
        >
          <option>All</option>
          {renderFilters()}
        </select>
      </div> */}
    </div>
  );
};

export default CustomFilterSelect;
